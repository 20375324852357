import VueRouter from 'vue-router'
import Vue from 'vue'
import myHome from "@@/views/myHome.vue"
import myApp from "@@/views/myApp.vue"
// 防止重复点击路由，导致提示避免到当前位置的冗余导航（路由冗余）解决方式
const routerRePush = VueRouter.prototype.push
VueRouter.prototype.push = function (location) {
    return routerRePush.call(this, location).catch(error => error)
}
// 通过vue.use(插件)，安装插件
Vue.use(VueRouter)
// 创建路由对象
const routes = [
    {
        path: '/',
        component: myApp,
        children:[
            {
                path: '/home',
                name: "MyHome",
                component: myHome,
                meta: {
                    title: '国资智采-电子招投标采购平台-中国招标采购网-政府招标项目公告 - 国资智采'
                }
            },
            {
                path: '/download',
                name: "Download",
                component: () => import("@@/views/myDownload.vue"),
                meta: {
                    title: '下载中心-国资智采'
                }
            },
            {
                path: '/myExpert',
                name: "Expert",
                component: () => import("@@/views/myExpert.vue"),
                meta: {
                    title: '平台专家库-国资智采'
                }
            },
            {
                path: '/about',
                name: "myAbout",
                component: () => import("@@/views/myAbout.vue"),
                meta: {
                    title: '交易信息-国资智采'
                }
            },
            {
                path: '/ggDetail',
                name: "ggDetail",
                component: () => import("@@/views/ggDetail.vue"),
                meta: {
                    title: '交易信息-国资智采'
                }
            },
            {
                path: '/collectGgDetail',
                name: "collectGgDetail",
                component: () => import("@@/views/collectGgDetail.vue"),
                meta: {
                    title: '征集公告-国资智采'
                }
            },
            {
                path: '/newsDetail',
                name: "newsDetail",
                component: () => import("@@/views/newsDetail.vue"),
                meta: {
                    title: '新闻公告-国资智采'
                }
            }, {
                path: '/help',
                name: "Help",
                component: () => import("@@/views/myHelp.vue"),
                meta: {
                    title: '帮助中心-国资智采'
                }
            }, {
                path: '/helpDetail',
                name: "helpDetail",
                component: () => import("@@/views/helpDetail.vue"),
                meta: {
                    title: '帮助中心-国资智采'
                }
            }, {
                path: '/ourself',
                name: "OurSelf",
                component: () => import("@@/views/ourSelf.vue"),
                meta: {
                    title: '关于我们-国资智采'
                }
            },
            // {
            //     path: '/Bidding',
            //     name: "Bidding",
            //     component: () => import("@@/views/Bidding/index.vue"),
            //     meta: {
            //         title: '竞价-国资智采'
            //     }
            // },
            // {
            //     path: '/bidingmain',
            //     name: "BiddingMain",
            //     component: () => import("@@/views/BiddingMain.vue"),
            //     meta: {
            //         title: '关于我们-国资智采'
            //     }
            // },{
            //     path: '/bidmain',
            //     name: "BidMain",
            //     component: () => import("@@/views/BidMain.vue"),
            //     meta: {
            //         title: '关于我们-国资智采'
            //     }
            // },
        ]
    },
    {
        path:'/login',
        name:'loginMain',
        component:()=>import("@@/views/LoginMain.vue")
    },
    {
        path:'/PBLoginMain',
        name:'PBLoginMain',
        component:()=>import("@@/views/PBLoginMain.vue")
    },
    {
        path:'/superviseLogin',
        name:'SuperviseLogin',
        component:()=>import("@@/views/SuperviseLoginMain.vue")
    },
    {
        path:'/register',
        name:'RegisterMain',
        component:()=>import("@@/views/RegisterMain.vue"),
        meta: {
            title: '注册-国资智采'
        }
    },
    {
        path: '/vendorRegister',
        name: 'vendorRegisterMain',
        component: () => import("@@/views/vendorRegisterMain.vue"),
        meta: {
            title: '供应商入库-国资智采'
        }
    },
    {
        path:'/marketRegister',
        name:'marketRegister',
        component:()=>import("@@/views/marketRegister.vue")
    },
    {
        path:'/specialZone',
        name:'specialZone',
        component:()=>import("@/views/procurementZone/specialZone.vue"),
        meta: {
            title: '采购专区'
        }
    },
    {
        path:'/LoginZone',
        name:'LoginMainZone',
        component:()=>import("@/views/procurementZone/LoginMain.vue"),
        meta: {
            title: '采购专区-登录'
        }
    },
    {
        path:'/RegisterZone',
        name:'RegisterMainZone',
        component:()=>import("@/views/procurementZone/RegisterMain.vue"),
        meta: {
            title: '采购专区-注册'
        }
    },
    // {
    //     path: '/BiddingHall',
    //     name: "BiddingHall",
    //     component: () => import("@@/views/Bidding/BiddingHall.vue"),
    //     meta: {
    //         title: '竞价厅-国资智采'
    //     }
    // },

    {path:'*',redirect:'/home'},
     {
        path:'/eat',
        name: 'ExtAppTokenHandler',
        component: () => import("@@/components/ExtAppTokenHandler"),
    }
]
const router = new VueRouter({
    // 配置URL和组价直接的映射关系
    routes,
    // history模式
    mode: 'history'
})
// 将router对象传入到vue实例中
export default router
